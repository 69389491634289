import UAParser from 'ua-parser-js';
import { inject } from './load-script';

// async function fetchWithTimeout(resource, options?) {
//   const controller = new AbortController();
//   const id = setTimeout(() => controller.abort(), 2000);
//   const response = await fetch(resource, { ...options, signal: controller.signal });
//   clearTimeout(id);
//
//   return response;
// }

(async function () {
  const url = Array.from(document.getElementsByTagName('script'))
    .map((item) => item.attributes.getNamedItem('src')?.value)
    .filter((item) => {
      const itemStr = String(item);
      return itemStr.includes('widget.metadialog.com') || itemStr.includes('smartbot-chat');
    });

  const isDev = process.env.NODE_ENV === 'development';
  const baseUrl = url[0] ? new URL(url[0]) : { origin: isDev ? 'http://localhost:3020' : '' };
  const envScriptUrl = `${baseUrl.origin}/__ENV.js`;
  const manifestUrl = `${baseUrl.origin}/asset-manifest.json`;

  inject(envScriptUrl)
    .then(() => {
      fetch(manifestUrl)
        .then((response) => response.json())
        .then((manifest) => {
          const appUrl = `${baseUrl.origin}${manifest.files['app.js']}`;

          inject(appUrl)
            .then(() => {
              window.SmartChatBotApp = new window.SBI();

              const parser = new UAParser();
              parser.setUA(window.navigator.userAgent);
              const result = parser.getResult();

              // fetchWithTimeout('https://api.db-ip.com/v2/free/self')
              //   .then((response) => response.json())
              //   .then((info) => {
              //     result.location = info;
              //     const event = new CustomEvent('chatbotready', {
              //       bubbles: true,
              //       cancelable: true,
              //       detail: { data: JSON.stringify(result) },
              //     });
              //     window.dispatchEvent(event);
              //   })
              //   .catch(() => {
              //     const event = new CustomEvent('chatbotready', {
              //       bubbles: true,
              //       cancelable: true,
              //       detail: { data: JSON.stringify(result) },
              //     });
              //     window.dispatchEvent(event);
              //   });

              const event = new CustomEvent('chatbotready', {
                bubbles: true,
                cancelable: true,
                detail: { data: JSON.stringify(result) },
              });
              window.dispatchEvent(event);
            })
            .catch(console.error);
        });
    })
    .catch(console.error);
})();

export {};
